import React from "react";
import styled from "styled-components";

const ProductName = styled.h1`

`
const Title = styled.h2`

`
const TextDivide = styled.hr`
    background-image: linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%);
    width: 30px;
    height: 4px;
    margin: 0;
    border: none;


`
const Paragraph = styled.p`

`
const CTA = styled.button`

 
`



const TextGroup = props => (
    <article>
    <ProductName className={props.color}>{props.product}</ProductName>
    <Title className={props.color}>{props.title}</Title>
    <TextDivide></TextDivide> 
    <Paragraph className={props.color}>{props.text}</Paragraph>
    <CTA><a href={props.url}>{props.cta}</a></CTA>
    </article>
)



export default TextGroup